<template>
    <div class="mt-2 btn-group" role="group" aria-label="Ações">
        <slot>
            <button type="button" class="btn btn-success" aria-label="Left Align"  @click.prevent.stop="onSubmit($event,'')" v-if="modo !== 'deletar' && modo !== 'cancelar' && modo !== 'visualizar' && modo !== 'readonly'">
                <i class="fa fa-check fa-lg text-white" aria-hidden="true"></i> Gravar e Voltar </button>
            <button type="button" class="btn btn-info text-white" aria-label="Left Align"  @click.prevent.stop="onSubmit($event,'andNew')" v-if="modo !== 'editar' && modo !== 'deletar' && modo !== 'cancelar' && modo !== 'visualizar' && modo !== 'readonly' && (modal == '' || (typeof modal == 'undefined'))">
                <i class="fa fa-check fa-lg text-white" aria-hidden="true"></i> Gravar e Incluir Novo </button>
            <button type="button" class="btn btn-danger" aria-label="Left Align" @click.prevent.stop="onSubmit($event)" v-if="modo === 'deletar'">
                <i class="fa fa-trash-o fa-lg text-white" aria-hidden="true"></i> Excluir </button>
            <button type="button" class="btn btn-danger" aria-label="Left Align" @click.prevent.stop="onSubmit($event)" v-if="modo === 'cancelar'">
                <i class="fa fa-trash-o fa-lg text-white" aria-hidden="true"></i> Cancelar </button>
        </slot>
        <button type="button" class="btn btn-dark" aria-label="Left Align" @click.prevent.stop="$bvModal.hide(modal)" v-if="modal !== '' && (typeof modal != 'undefined')">
            <i class="fa fa-chevron-left fa-lg text-white" aria-hidden="true"></i> Fechar </button>
        <button type="button" class="btn btn-dark" aria-label="Left Align" @click.prevent.stop="$router.back()" v-else>
            <i class="fa fa-chevron-left fa-lg text-white" aria-hidden="true"></i> Voltar </button>
    </div>
</template>

<script>
export default {
    props: ['modo','modal'],    
    methods: {
        onSubmit(ev, tipo){
            ev.preventDefault()
            this.$emit('submit', tipo)
        }    
    }  
}
</script>
